import axios from "axios";
import { store } from "../redux/storeConfig/store";
import { handleClearCache } from "../redux/actions/cache/index";
import { handleSetIdle } from "../redux/actions/idle/index";
import { Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { authBaseUrl } from "../views/Auth/api";
import {
  authRequestHeaders,
  getProviderAuthUrl,
  initRefreshTokenParams,
  initTokenExchangeApiParams,
  isImpersonated,
  refreshToken,
  updateSessionTokens,
} from "./Utils";

const headers = {};
if (sessionStorage.getItem("accessToken"))
  headers.Authorization = `Bearer ${sessionStorage.getItem("accessToken")}`;

const baseURL = window._env_.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers,
});

const createAxiosResponseInterceptor = () => {
  const interceptor = instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        sessionStorage.getItem("userData") &&
        sessionStorage.getItem("accessToken") &&
        sessionStorage.getItem("refreshToken")
      ) {
        if (
          error.response?.status === 402 &&
          window.location.pathname !== "/bundle-expired"
        ) {
          // return <Navigate to="/bundle-expired" />;
          createBrowserHistory().push("/bundle-expired");
          window.location.reload();
          return;
        }
        if (error.response?.status !== 401) {
          // Reject promise if usual error
          return Promise.reject(error);
        }

        instance.interceptors.response.eject(interceptor);
        return instance
          .post(
            getProviderAuthUrl(),
            initRefreshTokenParams(),
            authRequestHeaders
          )
          .then((res) => {
            updateSessionTokens({ ...res.data, isParentTokens: true });
            if (isImpersonated()) {
              // Refresh impersonated user tokens
              return instance
                .post(
                  getProviderAuthUrl(),
                  initTokenExchangeApiParams(),
                  authRequestHeaders
                )
                .then((res) => {
                  updateSessionTokens({ ...res.data, isParentTokens: false });
                  error.response.config.headers[
                    "Authorization"
                  ] = `Bearer ${res.data.access_token}`;
                  return instance(error.response.config);
                })
                .catch((err) => {
                  // handle user logout
                  // if browser tab has been inactive for more than 30 minutes and the refresh token has not been updated
                  // != 403
                  if (
                    err.response.status == 401 ||
                    err.response.status == 400
                  ) {
                    store.dispatch(handleSetIdle(true));
                  }
                  // sessionStorage.clear();
                  // // clear cache
                  // store.dispatch(handleClearCache());
                  // window.location.href = "/login";
                  Promise.reject(err);
                })
                .finally(createAxiosResponseInterceptor);
            } else {
              error.response.config.headers[
                "Authorization"
              ] = `Bearer ${res.data.access_token}`;
              return instance(error.response.config);
            }
          })
          .catch((err) => {
            // handle user logout
            // if browser tab has been inactive for more than 30 minutes and the refresh token has not been updated
            // != 403
            if (err.response.status == 401 || err.response.status == 400) {
              store.dispatch(handleSetIdle(true));
            }
            // sessionStorage.clear();
            // // clear cache
            // store.dispatch(handleClearCache());
            // window.location.href = "/login";
            Promise.reject(err);
          })
          .finally(() => {
            if (!isImpersonated()) {
              createAxiosResponseInterceptor();
            }
          });
      }
    }
  );
};
createAxiosResponseInterceptor();
export default instance;
