// **  Initial State
const initialState = { subscription: {} };

const planSubscriptionReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case "UPDATE_SUBSCRIBTION": {
      Object.assign(state.subscription, action.subscription);
      return {
        ...state,
        subscription: { ...state.subscription, ...action.subscription },
      };
    }
    // return {

    //   ...state,
    //   ...action.subscription,
    // };
    default:
      return state;
  }
};

export default planSubscriptionReducer;
