/* eslint-disable */
export const invitationsData = [
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
  {
    accountCreated: true,
    companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    creationDate: "2022-01-17T15:59:55.534Z",
    email: "mocked email",
    expiryDate: "2022-01-17T15:59:55.534Z",
    firstName: "John",
    id: 0,
    lastName: "Doe",
    lastUpdated: "2022-01-17T15:59:55.534Z",
    redirectUri: "string",
    roles: "string",
    status: "CANCELLED",
    userId: 0,
  },
];
