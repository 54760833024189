/* eslint-disable */
export const accounts = [
  {
    "id": 1,
    "userId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "firstName": "Eosb",
    "lastName": "Admin",
    "userName": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "email": "eosb.admin@finflx.com",
    "creationDate": "2021-03-11T13:49:43.359663Z",
    "lastUpdated": "2021-04-11T13:49:43.560862Z",
    "roles": "eosb-admin",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "3e58df50-ce5c-486f-a394-6a643525e2e9",
    "enabled": true,
    "onboardingStatus": "REGISTERED"
  },
  {
    "id": 2,
    "userId": "3e58df50-ce5c-486f-a394-6a643525e2e9",
    "firstName": "Admin",
    "lastName": "User",
    "userName": "3e58df50-ce5c-486f-a394-6a643525e2e9",
    "email": "admin@company.com",
    "creationDate": "2021-05-11T13:49:43.359663Z",
    "lastUpdated": "2021-07-11T13:49:43.560862Z",
    "roles": "academy-user,eosb-company-admin",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
    "enabled": false,
    "onboardingStatus": "REGISTERED"
  },
  {
    "id": 3,
    "userId": "b2a79087-5718-4c93-989f-2c9b9781c9fe",
    "firstName": "Matthieu",
    "lastName": "Capelle",
    "userName": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "email": "matt@gmail.com",
    "creationDate": "2021-03-11T13:49:43.359663Z",
    "lastUpdated": "2021-04-11T13:49:43.560862Z",
    "roles": "eosb-user,eosb-hr",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "3e58df50-ce5c-486f-a394-6a643525e2e9",
    "enabled": true,
    "onboardingStatus": "REGISTERED"
  },
  {
    "id": 4,
    "userId": "2f694a98-e04c-4e92-b90c-5301eb0639a0",
    "firstName": "Academy",
    "lastName": "User",
    "userName": "test.testing",
    "email": "academy@company.com",
    "creationDate": "2021-05-11T13:49:43.359663Z",
    "lastUpdated": "2021-06-11T13:49:43.560862Z",
    "roles": "academy-user",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
    "enabled": false,
    "onboardingStatus": "REGISTERED"
  },
  {
    "id": 5,
    "userId": "dd14f958-58ae-4793-b8cc-5f7f86a81bde",
    "firstName": "Full",
    "lastName": "Rights",
    "userName": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "email": "full.rights@finflx.com",
    "creationDate": "2021-03-11T13:49:43.359663Z",
    "lastUpdated": "2021-04-11T13:49:43.560862Z",
    "roles": "eosb-admin,academy-user,eosb-user,eosb-hr,eosb-finance,eosb-company-admin",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "3e58df50-ce5c-486f-a394-6a643525e2e9",
    "enabled": true,
    "onboardingStatus": "REGISTERED"
  },
  {
    "id": 6,
    "userId": "1f01d5e8-43fa-4b0b-a359-2b890f7085ce",
    "firstName": "Existing",
    "lastName": "Investor",
    "userName": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "email": "existing.investor@finflx.com",
    "creationDate": "2021-03-11T13:49:43.359663Z",
    "lastUpdated": "2021-04-11T13:49:43.560862Z",
    "roles": "academy-user,eosb-user",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "3e58df50-ce5c-486f-a394-6a643525e2e9",
    "enabled": true,
    "onboardingStatus": "ONBOARDED"
  },
  {
    "id": 7,
    "userId": "0cb5a75b-5f36-4e78-a470-8b529125d663",
    "firstName": "New",
    "lastName": "Investor",
    "userName": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "email": "new.investor@finflx.com",
    "creationDate": "2021-03-11T13:49:43.359663Z",
    "lastUpdated": "2021-04-11T13:49:43.560862Z",
    "roles": "academy-user,eosb-user",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "3e58df50-ce5c-486f-a394-6a643525e2e9",
    "enabled": true,
    "onboardingStatus": "REGISTERED"
  },
  {
    "id": 8,
    "userId": "0b17f9c5-3507-434c-907e-e830f00da8bb",
    "firstName": "John",
    "lastName": "Smith",
    "userName": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "email": "john.smith@company.com",
    "creationDate": "2021-03-11T13:49:43.359663Z",
    "lastUpdated": "2021-04-11T13:49:43.560862Z",
    "roles": "academy-user,eosb-user,eosb-hr,eosb-finance",
    "authServerId": "5d0d46a9-838a-4267-8f3e-8975113d854e",
    "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
    "enabled": true,
    "onboardingStatus": "REGISTERED"
  },
]
