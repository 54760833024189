export const handleUpdatePlanSubscription = (subscription) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SUBSCRIBTION",
      subscription,
      // createdAt,
      // result,
    });
  };
};
