import mock from "../mock";
import { paginateArray, sortCompare } from "../utils";

import { simulationsData } from "./simulations_data";

/* eslint-disable */
const data = {
  simulations: Object.entries(simulationsData).map(([k, v]) => v),
  blankSimulation: {
    name: null,
    id: null,
    creationDate: "2022-04-28T05:49:33+00:00",
    author: null,
    coverage: {
      gratuity: 0,
      currency: null,
      ratio: 0,
      initialMonthlyContribAvg: 0,
      maintenanceMonthlyContribAvg: 0,
    },
    criteria: {
      name: null,
      asOfDate: "2021-12-10T00:00:00Z",
      start: "2021-12-10T00:00:00Z",
      end: "2022-12-10T00:00:00Z",
      freq: "M",
      type: "COVERAGE",
      period: "12M",
      ratio: 0.03,
      frequency: "M",
      cashflow: null,
    },
    liability: {
      labels: [],
      series: [
        {
          name: "Covered Liabilities",
          type: "area",
          data: [],
        },
        {
          name: "Total Liabilities",
          type: "area",
          data: [],
        },
      ],
    },
    contributions: {
      labels: [],
      series: [
        {
          name: "Cash Flows",
          type: "bar",
          data: [],
        },
      ],
    },
  },
};
/* eslint-disable */

// ------------------------------------------------
// GET: Return simulations
// ------------------------------------------------
mock.onGet("/api/simulations").reply((config) => {
  const { q = "", perPage = 10, page = 1, sort = "id,desc" } = config.params;

  const queryLowered = q.toLowerCase();

  let filteredData;
  try {
    filteredData = data.simulations.filter((simulation) =>
      simulation.name.toLowerCase().includes(queryLowered)
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "desc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new simulation
// ------------------------------------------------
mock.onPost("/api/simulations").reply((config) => {
  // Get event from post data
  const simulation = JSON.parse(config.data);
  simulation.id = data.simulations.length + 1;
  data.simulations.push(simulation);

  return [201, simulation];
});

// ------------------------------------------------
// PUT: Perform a new simulation
// ------------------------------------------------
mock.onPut("/api/simulations").reply((config) => {
  // Get event from post data
  const simulation = JSON.parse(config.data);

  data.simulations = data.simulations.map((item) => {
    if (item.id === simulation.id) {
      return simulation;
    }
    return item;
  });

  return [201, simulation];
});

// ------------------------------------------------
// GET: Return Single simulation
// ------------------------------------------------
mock.onGet(/\/api\/simulations\/\d+/).reply((config) => {
  // Get event id from URL
  let simulationId = config.url.substring(config.url.lastIndexOf("/") + 1);

  if (simulationId == 0) return [200, data.blankSimulation];

  const simulationIndex = data.simulations.findIndex(
    (e) => e.id == simulationId
  );

  const simulation = data.simulations[simulationIndex];

  if (simulation) return [200, simulation];

  return [404];
});

// ------------------------------------------------
// POST: Perform a new simulation
// ------------------------------------------------
mock.onPost(/\/api\/simulations\/compute?.*/).reply((config) => {
  // Get event from post data
  const query = JSON.parse(config.data);
  var key = "";
  if (query.type == "COMPLIANCE") {
    key = "COMPLIANCE";
  } else if (query.type == "CASHFLOW") {
    key = `CASHFLOW-${query.period}-${query.amount}`;
  } else {
    key = "COVERAGE-";
    key += query.period;
    if (query.ratio <= 0.02) {
      key += "-0.02";
    } else if (query.ratio <= 0.05) {
      key += "-0.05";
    } else if (query.ratio <= 0.1) {
      key += "-0.1";
    } else if (query.ratio <= 0.2) {
      key += "-0.2";
    } else if (query.ratio <= 0.3) {
      key += "-0.3";
    } else {
      key += "-0.5";
    }
  }

  return [201, simulationsData[key]];
});
