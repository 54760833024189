// **  Initial State
const initialState = {
  hasContributions: false,
};

const contributionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HAS_CONTRIBUTIONS":
      return {
        ...state,
        hasContributions: action.hasContributions,
      };
    default:
      return state;
  }
};

export default contributionsReducer;
