// ** Initial State
const initialState = {
  dashboard: null,
};

const cacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CACHE":
      return { ...state, [action.key]: action.data };
    case "CLEAR_CACHE":
      return { ...state, dashboard: null };

    default:
      return state;
  }
};

export default cacheReducer;
