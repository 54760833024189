import mock from "../mock";
import useJwt from "../../auth/jwt/useJwt";
import jwt from "jsonwebtoken";
import { accounts } from "./accounts";
import { authBaseUrl } from "../../views/Auth/api";

mock.onPost(/\/api\/user-accounts\/forgot-password?.*/).reply(() => [200]);

mock.onPut("/api/user-accounts/reset-password").reply(() => [200]);

mock.onPost(/\/api\/user-accounts\/signup?.*/).reply((config) => {
  const hasToken = config.url.substring(config.url.lastIndexOf("=") + 1);
  if (hasToken) return [200];

  return [404, { data: "Error" }];
});

mock.onGet("/api/user-accounts/me").reply(() => {
  const userData = jwt.decode(useJwt.getToken());
  const userIndex = accounts.findIndex(
    (c) => c.email.toString() === userData.email.toString()
  );

  const user = accounts[userIndex];

  if (user) return [200, user];

  return [200, accounts[4]];
});

const jwtResponse = {
  access_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJKYW5lIERvZSIsImNvbXBhbnlpZCI6MTEsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJlb3NiLXVzZXIiLCJlb3NiLWFkbWluIiwiZW9zYi1ociIsImVvc2ItZmluYW5jZSJdfSwibmFtZSI6IkphbmUgRG9lIiwiZ2l2ZW5fbmFtZSI6IkphbmUiLCJmYW1pbHlfbmFtZSI6IkRvZSIsInVzZXJpZCI6IjY0YzFmMjE4LTIzNzgtNDFlMi1iNjY5LTQwMWFjMDJiOTM1NSIsImVtYWlsIjoiamFuZS5kb2VAY29tcGFueS5jb20iLCJpYXQiOjE2MjM2NTM5MTMsImV4cCI6NDc3NzI1MzkxM30.gdRgHOeAlEnmLrHtQmJzNgqTP_dtUoRjPJrB9B4qhsaKv-mjKLzHpPgeN3A1DortDDVS8dRTGsG-sW1UuKEskRhKhjbGwTc9zNsdN928ulnMyc76OVcb2h8t2UdAFJ21w52Rv17S356HnmbnklU7VrKbWfx7gLqeK7vdOeDyO2NR9gyczKWLLLY5khKP3AhaXsm0nO-7RtvoKB2ioo-0uDIffEo619cGZAKMS4MHKZdAE_iyzE5JNhf8eAKYQQ6vv7lpys8A_QcUUSyiiODzhtm6J198RPe3FJkGI8FQEkiF94ZDdniGeNjL5OKPQ6qPb6XCIfdVsXIbKCMx1pWs5A",
  expires_in: 300,
  refresh_expires_in: 1800,
  refresh_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJKYW5lIERvZSIsImNvbXBhbnlpZCI6MTEsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJlb3NiLXVzZXIiLCJlb3NiLWFkbWluIiwiZW9zYi1ociIsImVvc2ItZmluYW5jZSJdfSwibmFtZSI6IkphbmUgRG9lIiwiZ2l2ZW5fbmFtZSI6IkphbmUiLCJmYW1pbHlfbmFtZSI6IkRvZSIsInVzZXJpZCI6IjY0YzFmMjE4LTIzNzgtNDFlMi1iNjY5LTQwMWFjMDJiOTM1NSIsImVtYWlsIjoiamFuZS5kb2VAY29tcGFueS5jb20iLCJpYXQiOjE2MjM2NTM5MTMsImV4cCI6NDc3NzI1MzkxM30.gdRgHOeAlEnmLrHtQmJzNgqTP_dtUoRjPJrB9B4qhsaKv-mjKLzHpPgeN3A1DortDDVS8dRTGsG-sW1UuKEskRhKhjbGwTc9zNsdN928ulnMyc76OVcb2h8t2UdAFJ21w52Rv17S356HnmbnklU7VrKbWfx7gLqeK7vdOeDyO2NR9gyczKWLLLY5khKP3AhaXsm0nO-7RtvoKB2ioo-0uDIffEo619cGZAKMS4MHKZdAE_iyzE5JNhf8eAKYQQ6vv7lpys8A_QcUUSyiiODzhtm6J198RPe3FJkGI8FQEkiF94ZDdniGeNjL5OKPQ6qPb6XCIfdVsXIbKCMx1pWs5A",
  token_type: "Bearer",
  id_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJKYW5lIERvZSIsImNvbXBhbnlpZCI6MTEsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJlb3NiLXVzZXIiLCJlb3NiLWFkbWluIiwiZW9zYi1ociIsImVvc2ItZmluYW5jZSJdfSwibmFtZSI6IkphbmUgRG9lIiwiZ2l2ZW5fbmFtZSI6IkphbmUiLCJmYW1pbHlfbmFtZSI6IkRvZSIsInVzZXJpZCI6IjY0YzFmMjE4LTIzNzgtNDFlMi1iNjY5LTQwMWFjMDJiOTM1NSIsImVtYWlsIjoiamFuZS5kb2VAY29tcGFueS5jb20iLCJpYXQiOjE2MjM2NTM5MTMsImV4cCI6NDc3NzI1MzkxM30.gdRgHOeAlEnmLrHtQmJzNgqTP_dtUoRjPJrB9B4qhsaKv-mjKLzHpPgeN3A1DortDDVS8dRTGsG-sW1UuKEskRhKhjbGwTc9zNsdN928ulnMyc76OVcb2h8t2UdAFJ21w52Rv17S356HnmbnklU7VrKbWfx7gLqeK7vdOeDyO2NR9gyczKWLLLY5khKP3AhaXsm0nO-7RtvoKB2ioo-0uDIffEo619cGZAKMS4MHKZdAE_iyzE5JNhf8eAKYQQ6vv7lpys8A_QcUUSyiiODzhtm6J198RPe3FJkGI8FQEkiF94ZDdniGeNjL5OKPQ6qPb6XCIfdVsXIbKCMx1pWs5A",
  session_state: "83ea849d-28d6-4e9e-97a0-1fedec58ec58",
  scope: "openid email companyid profile",
};

const finflxAdminToken = {
  access_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJFb3NiIEFkbWluIiwiY29tcGFueWlkIjoiNDkyNDZiNmEtNjllMC00M2U2LTk2NjUtMjk5Zjc4MWNkOTA3IiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImVvc2ItYWRtaW4iXX0sIm5hbWUiOiJFb3NiIEFkbWluIiwiZ2l2ZW5fbmFtZSI6IkVvc2IiLCJmYW1pbHlfbmFtZSI6IkFkbWluIiwidXNlcmlkIjoiNGUxMTMwOGUtNTBjNC00OTM4LWI3MjItNGU3YjBjZTU2ODFkIiwiZW1haWwiOiJlb3NiLmFkbWluQGZpbmZseC5jb20iLCJpYXQiOjE2Mjk4MDA1NTMsImV4cCI6NDc4MzQwMDU1M30.QxGCOH8ySreMixCS2VGnWt12yNFTEoirl6BSw0Y9Pyk6jJdYaZuq-F1Iw8qMEpXxgwFmo6dL0sLbu72buUeMS3nV1vdeaEH-g0Tb2_whgOWIm7gqRKbUWYBa7Xr-u54-NDWFxsD6DggGsgxe_RCaQoKNxIqmb9cJpzNZJxGiuvdJ3Eaiw5H5M_265OmdfBJm6R7q9r0ZAeLn_ltqrEB9kD8-WxH4y16oHErlsWAIahZ8eLuox41617P-hYOTRarMtrx4i9XVrpIrgPN_OyRUbFNTAtqK4XzhPep3n_Yu8kWBqdrzxJEu9xaWgslM-VSpEiZHzUtYL7dGTK6KjdfBCA",
  refresh_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJFb3NiIEFkbWluIiwiY29tcGFueWlkIjoiNDkyNDZiNmEtNjllMC00M2U2LTk2NjUtMjk5Zjc4MWNkOTA3IiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImVvc2ItYWRtaW4iXX0sIm5hbWUiOiJFb3NiIEFkbWluIiwiZ2l2ZW5fbmFtZSI6IkVvc2IiLCJmYW1pbHlfbmFtZSI6IkFkbWluIiwidXNlcmlkIjoiNGUxMTMwOGUtNTBjNC00OTM4LWI3MjItNGU3YjBjZTU2ODFkIiwiZW1haWwiOiJlb3NiLmFkbWluQGZpbmZseC5jb20iLCJpYXQiOjE2Mjk4MDA1NTMsImV4cCI6NDc4MzQwMDU1M30.QxGCOH8ySreMixCS2VGnWt12yNFTEoirl6BSw0Y9Pyk6jJdYaZuq-F1Iw8qMEpXxgwFmo6dL0sLbu72buUeMS3nV1vdeaEH-g0Tb2_whgOWIm7gqRKbUWYBa7Xr-u54-NDWFxsD6DggGsgxe_RCaQoKNxIqmb9cJpzNZJxGiuvdJ3Eaiw5H5M_265OmdfBJm6R7q9r0ZAeLn_ltqrEB9kD8-WxH4y16oHErlsWAIahZ8eLuox41617P-hYOTRarMtrx4i9XVrpIrgPN_OyRUbFNTAtqK4XzhPep3n_Yu8kWBqdrzxJEu9xaWgslM-VSpEiZHzUtYL7dGTK6KjdfBCA",
};

const academyUserToken = {
  access_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJBY2FkZW15IFVzZXIiLCJjb21wYW55aWQiOiJhMGM3Nzc0MC00Y2M3LTQ1ZGItOGM1ZS0wNWVlYzhhM2VlZjYiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiYWNhZGVteS11c2VyIl19LCJuYW1lIjoiQWNhZGVteSBVc2VyIiwiZ2l2ZW5fbmFtZSI6IkFjYWRlbXkiLCJmYW1pbHlfbmFtZSI6IlVzZXIiLCJ1c2VyaWQiOiIwOTIxOGVkMC1jOTFiLTRlMjgtOGM0NS1hNGMyNjIxODBhZDQiLCJlbWFpbCI6ImFjYWRlbXlAY29tcGFueS5jb20iLCJpYXQiOjE2Mjk4MDA1NTMsImV4cCI6NDc4MzQwMDU1M30.jrKpILdZvJ9jl7k_rs_IRNn8O_gncj8-nDENrVAwoERL6UPDFcvah6ChPnDWtYTKHE33QhAow2gU5qOZCnkSID1dIKfAzG8xgtMuK7jlGoJ2GnPpvJPxa1acx8sLG2eu_fKl5TyjrprXUejqn3mtWD7iu6iv9ey_nYmuteiZakvXQELUcxkenGdPM5LB76w2QH-krfuenyBv_r38JkBjI1rGnYxCE_6FNQh6215ta6trNPq-zY55XlPCyaAfLFdMsnDnu5aiwOsSKDU-YDae8egtGwWXdnj8kXsocC5-fHRYHe4Mt91G8SS0Jow1b-1PD9nMkcM4GcqrU4jncFIduw",
  refresh_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJBY2FkZW15IFVzZXIiLCJjb21wYW55aWQiOiJhMGM3Nzc0MC00Y2M3LTQ1ZGItOGM1ZS0wNWVlYzhhM2VlZjYiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiYWNhZGVteS11c2VyIl19LCJuYW1lIjoiQWNhZGVteSBVc2VyIiwiZ2l2ZW5fbmFtZSI6IkFjYWRlbXkiLCJmYW1pbHlfbmFtZSI6IlVzZXIiLCJ1c2VyaWQiOiIwOTIxOGVkMC1jOTFiLTRlMjgtOGM0NS1hNGMyNjIxODBhZDQiLCJlbWFpbCI6ImFjYWRlbXlAY29tcGFueS5jb20iLCJpYXQiOjE2Mjk4MDA1NTMsImV4cCI6NDc4MzQwMDU1M30.jrKpILdZvJ9jl7k_rs_IRNn8O_gncj8-nDENrVAwoERL6UPDFcvah6ChPnDWtYTKHE33QhAow2gU5qOZCnkSID1dIKfAzG8xgtMuK7jlGoJ2GnPpvJPxa1acx8sLG2eu_fKl5TyjrprXUejqn3mtWD7iu6iv9ey_nYmuteiZakvXQELUcxkenGdPM5LB76w2QH-krfuenyBv_r38JkBjI1rGnYxCE_6FNQh6215ta6trNPq-zY55XlPCyaAfLFdMsnDnu5aiwOsSKDU-YDae8egtGwWXdnj8kXsocC5-fHRYHe4Mt91G8SS0Jow1b-1PD9nMkcM4GcqrU4jncFIduw",
};

const companyAdminToken = {
  access_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJBZG1pbiBVc2VyIiwiY29tcGFueWlkIjoiMjhjYTYzNjAtMjQ4NC00YjViLWFiNTgtNTk0MzhhNzNhMzBjIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImFjYWRlbXktdXNlciIsImVvc2ItY29tcGFueS1hZG1pbiJdfSwibmFtZSI6IkFkbWluIFVzZXIiLCJnaXZlbl9uYW1lIjoiQWRtaW4iLCJmYW1pbHlfbmFtZSI6IlVzZXIiLCJ1c2VyaWQiOiJmNWQyYWVmNC01MDRjLTQ1MzktYTliMi1kMmM4YjUzOTIyMGYiLCJlbWFpbCI6ImFkbWluQGNvbXBhbnkuY29tIiwiaWF0IjoxNjI5ODAwNTUzLCJleHAiOjQ3ODM0MDA1NTN9.hGjx5Z05PwOiKvFhrDqARTRfZkLtSflRelH-TWzBCYDSyesdXxQASDPWiOHcbgbn6wgl4QGma5BtqTWfGriP_7IfatN-Ej9TtIY6c72b9_e-SV6wma7CN4taGxnRGaOKMlTCKmXGRdjj0nxcmyKx7HG3Jffh8J2SU9atY1ONcVzdyd5z-J1LOPiKAQ6HKkNVHKz1hWUJ9uS5DZxZpBhCzCEFYJsqvltwCaPm8tqP4lrdW-iHUmQtV5WxNQEWYj6m9INoW2az--wgEAlu5a4tVPuOBtwDcxzd61jfRyszNeEUvizIIXDViz5YWY0w_kxtBlowbUg7kotAP6BIVjRgaw",
  refresh_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJBZG1pbiBVc2VyIiwiY29tcGFueWlkIjoiMjhjYTYzNjAtMjQ4NC00YjViLWFiNTgtNTk0MzhhNzNhMzBjIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImFjYWRlbXktdXNlciIsImVvc2ItY29tcGFueS1hZG1pbiJdfSwibmFtZSI6IkFkbWluIFVzZXIiLCJnaXZlbl9uYW1lIjoiQWRtaW4iLCJmYW1pbHlfbmFtZSI6IlVzZXIiLCJ1c2VyaWQiOiJmNWQyYWVmNC01MDRjLTQ1MzktYTliMi1kMmM4YjUzOTIyMGYiLCJlbWFpbCI6ImFkbWluQGNvbXBhbnkuY29tIiwiaWF0IjoxNjI5ODAwNTUzLCJleHAiOjQ3ODM0MDA1NTN9.hGjx5Z05PwOiKvFhrDqARTRfZkLtSflRelH-TWzBCYDSyesdXxQASDPWiOHcbgbn6wgl4QGma5BtqTWfGriP_7IfatN-Ej9TtIY6c72b9_e-SV6wma7CN4taGxnRGaOKMlTCKmXGRdjj0nxcmyKx7HG3Jffh8J2SU9atY1ONcVzdyd5z-J1LOPiKAQ6HKkNVHKz1hWUJ9uS5DZxZpBhCzCEFYJsqvltwCaPm8tqP4lrdW-iHUmQtV5WxNQEWYj6m9INoW2az--wgEAlu5a4tVPuOBtwDcxzd61jfRyszNeEUvizIIXDViz5YWY0w_kxtBlowbUg7kotAP6BIVjRgaw",
};

const existingInvestorToken = {
  access_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJFeGlzdGluZyBJbnZlc3RvciIsImNvbXBhbnlpZCI6IjNlNThkZjUwLWNlNWMtNDg2Zi1hMzk0LTZhNjQzNTI1ZTJlOSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJlb3NiLXVzZXIiLCJhY2FkZW15LXVzZXIiXX0sIm5hbWUiOiJFeGlzdGluZyBJbnZlc3RvciIsImdpdmVuX25hbWUiOiJFeGlzdGluZyIsImZhbWlseV9uYW1lIjoiSW52ZXN0b3IiLCJ1c2VyaWQiOiIxZjAxZDVlOC00M2ZhLTRiMGItYTM1OS0yYjg5MGY3MDg1Y2UiLCJlbWFpbCI6ImV4aXN0aW5nLmludmVzdG9yQGZpbmZseC5jb20iLCJpYXQiOjE2MzA0MTE3MDAsImV4cCI6NDc4NDAxMTcwMH0.Yu7wr8I3ptAqB6PxlFiQH9eUkegQnDMoe-Dmw1-bbWYARDQbm_1nURXlwzJJFgBwMEkSvwuwhBxTbQ1HnmRFK2tAenwESTkuxhhRWDXT3bcBZon4x1gjugT1_w4FHMosP_nnAn6M3271MC3wXmgEW5WlfT4DeVqJMRX2EH9dDB3aevvNSRjx_7dSXDUJRZtsjH3LcwpE_QbBAM_-S9jFjmShD3cMXH4jPrn0TPARYGX16CyVqiBF_DPAVyztlOxpJhRyFnHuMXNGCp4KVu8l54u5oOThiKbwH7YyWPm4l7P7y6dvLt7TqFwypbCcigtBnmkog9pLNGXAI584hooNFw",
  refresh_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJFeGlzdGluZyBJbnZlc3RvciIsImNvbXBhbnlpZCI6IjNlNThkZjUwLWNlNWMtNDg2Zi1hMzk0LTZhNjQzNTI1ZTJlOSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJlb3NiLXVzZXIiLCJhY2FkZW15LXVzZXIiXX0sIm5hbWUiOiJFeGlzdGluZyBJbnZlc3RvciIsImdpdmVuX25hbWUiOiJFeGlzdGluZyIsImZhbWlseV9uYW1lIjoiSW52ZXN0b3IiLCJ1c2VyaWQiOiIxZjAxZDVlOC00M2ZhLTRiMGItYTM1OS0yYjg5MGY3MDg1Y2UiLCJlbWFpbCI6ImV4aXN0aW5nLmludmVzdG9yQGZpbmZseC5jb20iLCJpYXQiOjE2MzA0MTE3MDAsImV4cCI6NDc4NDAxMTcwMH0.Yu7wr8I3ptAqB6PxlFiQH9eUkegQnDMoe-Dmw1-bbWYARDQbm_1nURXlwzJJFgBwMEkSvwuwhBxTbQ1HnmRFK2tAenwESTkuxhhRWDXT3bcBZon4x1gjugT1_w4FHMosP_nnAn6M3271MC3wXmgEW5WlfT4DeVqJMRX2EH9dDB3aevvNSRjx_7dSXDUJRZtsjH3LcwpE_QbBAM_-S9jFjmShD3cMXH4jPrn0TPARYGX16CyVqiBF_DPAVyztlOxpJhRyFnHuMXNGCp4KVu8l54u5oOThiKbwH7YyWPm4l7P7y6dvLt7TqFwypbCcigtBnmkog9pLNGXAI584hooNFw",
};

const newInvestorToken = {
  access_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJOZXcgSW52ZXN0b3IiLCJjb21wYW55aWQiOiIzZTU4ZGY1MC1jZTVjLTQ4NmYtYTM5NC02YTY0MzUyNWUyZTkiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZW9zYi11c2VyIiwiYWNhZGVteS11c2VyIl19LCJuYW1lIjoiTmV3IEludmVzdG9yIiwiZ2l2ZW5fbmFtZSI6Ik5ldyIsImZhbWlseV9uYW1lIjoiSW52ZXN0b3IiLCJ1c2VyaWQiOiIwY2I1YTc1Yi01ZjM2LTRlNzgtYTQ3MC04YjUyOTEyNWQ2NjMiLCJlbWFpbCI6Im5ldy5pbnZlc3RvckBmaW5mbHguY29tIiwiaWF0IjoxNjMwNDExNzAwLCJleHAiOjQ3ODQwMTE3MDB9.LY5MAz2cRz5Vd2VIC1drQXF3RX_qPyH2cT2-tLwllAFAj5bnGyCD5BhVWFpyIIVVVSQpN68J3FbCot3WRT9kSj962h78fjl8YUFap2qnkSR_c2yjERTIjBdqliMMB-kcvmv_PB2w-CeHXLaQ5tIWOKbBgGbS_ZVp20Dq5IBsjqlh7SAPshEI_OswzV3HBZElzexmF2HtC6R-EkqbuN2znsvhtXoDUeBWfcFTSOMYcuS0E6j8c4V7Y0duy_8hLILWAusdy62LaHkmu0ObQe4yV4Ch2Mojd_T-s6kUXE3kAvCXU6U8up2BMYp56Ijr--sUoOAIwq23ECZKvDL0LO2glg",
  refresh_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJOZXcgSW52ZXN0b3IiLCJjb21wYW55aWQiOiIzZTU4ZGY1MC1jZTVjLTQ4NmYtYTM5NC02YTY0MzUyNWUyZTkiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZW9zYi11c2VyIiwiYWNhZGVteS11c2VyIl19LCJuYW1lIjoiTmV3IEludmVzdG9yIiwiZ2l2ZW5fbmFtZSI6Ik5ldyIsImZhbWlseV9uYW1lIjoiSW52ZXN0b3IiLCJ1c2VyaWQiOiIwY2I1YTc1Yi01ZjM2LTRlNzgtYTQ3MC04YjUyOTEyNWQ2NjMiLCJlbWFpbCI6Im5ldy5pbnZlc3RvckBmaW5mbHguY29tIiwiaWF0IjoxNjMwNDExNzAwLCJleHAiOjQ3ODQwMTE3MDB9.LY5MAz2cRz5Vd2VIC1drQXF3RX_qPyH2cT2-tLwllAFAj5bnGyCD5BhVWFpyIIVVVSQpN68J3FbCot3WRT9kSj962h78fjl8YUFap2qnkSR_c2yjERTIjBdqliMMB-kcvmv_PB2w-CeHXLaQ5tIWOKbBgGbS_ZVp20Dq5IBsjqlh7SAPshEI_OswzV3HBZElzexmF2HtC6R-EkqbuN2znsvhtXoDUeBWfcFTSOMYcuS0E6j8c4V7Y0duy_8hLILWAusdy62LaHkmu0ObQe4yV4Ch2Mojd_T-s6kUXE3kAvCXU6U8up2BMYp56Ijr--sUoOAIwq23ECZKvDL0LO2glg",
};

const eosbUserToken = {
  access_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJKb2huIFNtaXRoIiwiY29tcGFueWlkIjoiMWQ5YTlmZGEtYzk0Mi00MTVjLTg0YTUtMjNjMjM5ZmY1ZmQwIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImVvc2ItdXNlciIsImVvc2ItaHIiLCJlb3NiLWZpbmFuY2UiLCJhY2FkZW15LXVzZXIiXX0sIm5hbWUiOiJKb2huIFNtaXRoIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlNtaXRoIiwidXNlcmlkIjoiMGIxN2Y5YzUtMzUwNy00MzRjLTkwN2UtZTgzMGYwMGRhOGJiIiwiZW1haWwiOiJqb2huLnNtaXRoQGNvbXBhbnkuY29tIiwiaWF0IjoxNjM1MDA4MjYwLCJleHAiOjQ3ODg2MDgyNjB9.Fg1xNQA2gWDwKqZf8skEAGUUivzKuiOeNtT-ysZm7TxnyiADqW8yDFhcKGHY8mpPJ8Qbg_-2Nr0jYMzegrUh55zOUhOvXCJ6Etpi1aNFLYGHNlBrs5E-4NsQsqJsrFPHFF553GwTUeI5xiRBvw0yEc2WRNV8zHieCsqfEkVERw7Q_VxsT4BNoB7NRH9BnqJCiECedIUplUjMzRNqCTHuAaiP-sdDht9TbjyM3LxCKxRm8bnR7l0TGnqL6XqBhE40erHkOk5Nq1gRaPkBEGwdf_0nNEu9L_-0roxkS91o428CcpB6wpgpeev39WUzIbwgUT3zlh1Fy1nTL7z0NYBtNQ",
  refresh_token:
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJKb2huIFNtaXRoIiwiY29tcGFueWlkIjoiMWQ5YTlmZGEtYzk0Mi00MTVjLTg0YTUtMjNjMjM5ZmY1ZmQwIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImVvc2ItdXNlciIsImVvc2ItaHIiLCJlb3NiLWZpbmFuY2UiLCJhY2FkZW15LXVzZXIiXX0sIm5hbWUiOiJKb2huIFNtaXRoIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlNtaXRoIiwidXNlcmlkIjoiMGIxN2Y5YzUtMzUwNy00MzRjLTkwN2UtZTgzMGYwMGRhOGJiIiwiZW1haWwiOiJqb2huLnNtaXRoQGNvbXBhbnkuY29tIiwiaWF0IjoxNjM1MDA4MjYwLCJleHAiOjQ3ODg2MDgyNjB9.Fg1xNQA2gWDwKqZf8skEAGUUivzKuiOeNtT-ysZm7TxnyiADqW8yDFhcKGHY8mpPJ8Qbg_-2Nr0jYMzegrUh55zOUhOvXCJ6Etpi1aNFLYGHNlBrs5E-4NsQsqJsrFPHFF553GwTUeI5xiRBvw0yEc2WRNV8zHieCsqfEkVERw7Q_VxsT4BNoB7NRH9BnqJCiECedIUplUjMzRNqCTHuAaiP-sdDht9TbjyM3LxCKxRm8bnR7l0TGnqL6XqBhE40erHkOk5Nq1gRaPkBEGwdf_0nNEu9L_-0roxkS91o428CcpB6wpgpeev39WUzIbwgUT3zlh1Fy1nTL7z0NYBtNQ",
};

mock.onPost(authBaseUrl).reply((request) => {
  const jwtPrefix =
    "client_id=eosb-orch-browser&grant_type=refresh_token&refresh_token=";

  let result;

  if (request.data.includes("eosb.admin%40finflx.com")) {
    result = finflxAdminToken;
  } else if (request.data.includes("admin%40company.com")) {
    result = companyAdminToken;
  } else if (request.data.includes("academy%40company.com")) {
    result = academyUserToken;
  } else if (request.data.includes("existing.investor%40finflx.com")) {
    result = existingInvestorToken;
  } else if (request.data.includes("new.investor%40finflx.com")) {
    result = newInvestorToken;
  } else if (request.data.includes("john.smith%40company.com")) {
    result = eosbUserToken;
  } else if (request.data.includes("grant_type=refresh_token")) {
    // Handle Refresh TOKEN, we get the last token provided
    result = {
      access_token: sessionStorage.getItem("mockAccessToken"),
      refresh_token: sessionStorage.getItem("mockAccessToken"),
    };
  } else {
    // We send the default token
    result = jwtResponse;
  }

  // We store the token to retrieve it on refresh
  sessionStorage.setItem("mockAccessToken", result.access_token);

  return [200, result];
});
