import mock from "../mock";
import { paginateArray, sortCompare } from "../utils";

import { invitationsData } from "./invitations_data";

// ------------------------------------------------
// GET: Return invitations
// ------------------------------------------------
mock.onGet(/\/api\/invitations?.*/).reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 6,
    page = 1,
    sort = "id,desc",
    departmentName = null,
  } = config.params;
  /* eslint-enable */
  // const queryLowered = q.toLowerCase();

  /* eslint-disable */
  // let filteredData;
  // try {
  //   filteredData = invitationsData.filter(
  //     (employee) =>
  //       /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
  //       (employee.hireDate.toLowerCase().includes(queryLowered) ||
  //         employee.departmentName.toLowerCase().includes(queryLowered) ||
  //         employee.email.toLowerCase().includes(queryLowered) ||
  //         employee.firstName.toLowerCase().includes(queryLowered) ||
  //         employee.lastName.toLowerCase().includes(queryLowered)) &&
  //       employee.departmentName === (departmentName || employee.departmentName)
  //   );
  // } catch (e) {
  //   console.error(e.name + ": " + e.message);
  // }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "desc";
  const sortedData = invitationsData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      empty: true,
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      size: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      totalPages: 0,
      totalPending: 0,
      totalJoinRequests: 0,
      totalElements: sortedData.length,
    },
  ];
  /* eslint-enable  */
});

// ------------------------------------------------
// POST: invite new employee
// ------------------------------------------------
mock.onPost(/\/api\/employees\/invite/).reply((config) => {
  return [201, []];
});
