// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import cache from "./cache";
import notification from "./notification";
import idle from "./idle";
import contributions from "./contributions";
import planSubscription from "./planSubscription";

const rootReducer = combineReducers({
  auth,
  contributions,
  navbar,
  layout,
  cache,
  notification,
  idle,
  planSubscription,
});

export default rootReducer;
