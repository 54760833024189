import mock from "./mock";
/* eslint-disable global-require */
/* eslint-disable  */

if (window._env_.REACT_APP_MOCK_BE === "true") {
  require("./data/dashboard");
  require("./data/employees");
  require("./data/invitations");
  require("./data/simulations");
  require("./data/admin");
  require("./data/cash");
}

if (window._env_.REACT_APP_MOCK_AUTH === "true") {
  require("./data/auth");
}
if (window._env_.REACT_APP_MOCK_INVESTMENTS === "true") {
  require("./data/investments");
}

/* eslint-enable import/extensions */

mock.onAny().passThrough(); // forwards the matched request over network
