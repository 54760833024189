// **  Initial State
const initialState = {
  notifications: {content: [],unreadNotifications:0}
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.data,
      }
    default:
      return state
  }
}

export default notificationsReducer
