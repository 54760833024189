import axios from "axios";
import moment from "moment";
import { handleUpdatePlanSubscription } from "../../redux/actions/planSubscription";
import { store } from "../../redux/storeConfig/store";
import instance from "../../utility/axios";
import { getUserData, initTokenExchangeApiParams } from "../../utility/Utils";
import useJwt from "../../auth/jwt/useJwt";

export const authBaseUrl = "/k-auth";

export function loginMethod({ email, password, callback }) {
  const formData = new URLSearchParams();
  formData.append("username", email);
  formData.append("password", password);
  formData.append("client_id", "eosb-orch-browser");
  formData.append("grant_type", "password");
  formData.append("scope", "openid");

  instance
    .post(authBaseUrl, formData)
    .then((res) => {
      callback(res.data);
    })
    .catch((error) => {
      callback(error);
    });
}

export function getCompanyState({ companyId, callback }) {
  if (sessionStorage.getItem("accessToken") !== null && companyId) {
    let cmState = instance.get(`api/plans/${companyId}/state`);
    let cmPlan = instance.get(`api/plans/${companyId}`);
    let currStateStr = sessionStorage.getItem("company_stats");
    let curState = currStateStr ? JSON.parse(currStateStr) : undefined;
    let lastCall = curState
      ? moment(curState?.createdAt).diff(moment(+new Date()), "seconds")
      : undefined;
    if (false) {
      // if (lastCall && lastCall <= 0.5) {
      callback(curState);
    } else {
      Promise.all([cmState, cmPlan])
        .then(([res, plan]) => {
          if (res.data) {
            sessionStorage.setItem(
              "company_stats",
              JSON.stringify({ ...res.data, createdAt: +new Date() })
            );
          }
          if (plan.data) {
            sessionStorage.setItem(
              "company_plan",
              JSON.stringify({ ...plan.data })
            );
            store.dispatch(handleUpdatePlanSubscription(plan.data));
          }

          callback(res.data);
        })
        .catch((error) => {
          callback(error, error.response?.status);
        });
    }
  } else {
    callback(null, 404);
  }
}
function createPlanPromise(companyId, token) {
  let req = instance
    .get("api/plans/" + companyId, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      } else {
        return instance
          .post(
            "/api/plans",
            {
              companyId: companyId,
              creationDate: moment().toISOString(),
            },
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          )
          .then((res) => res);
      }
    })
    .catch((err) => {
      return instance
        .post(
          "/api/plans",
          {
            companyId: companyId,
            creationDate: moment().toISOString(),
          },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => res);
    });
  return req;
}

export function meMethod({ token, callback }) {
  // var err = true;

  let req = token
    ? axios.get("/api/user-accounts/me", {
        headers: {
          authorization: `Bearer ${token}`,
        },
        baseURL: window._env_.REACT_APP_BASE_URL,
      })
    : instance.get("/api/user-accounts/me");
  req
    .then((res) => {
      // createPlanPromise(res.data?.companyId, token).then((res2) => {
      //   res
      //     ?
      getCompanyState({
        companyId: res.data.companyId,
        callback: () => {
          instance
            .get(`api/employees/onboarded/count`, {
              headers: {
                Authorization: `bearer ${token}`,
              },
            })
            .then((empRes) => {
              callback(res.data);
              sessionStorage.setItem(
                "employee_onboarded",
                empRes?.data?.count > 0 ? true : false
              );
            })
            .catch((error) => {
              callback(null);
              sessionStorage.setItem("employee_onboarded", false);
            });
        },
      });
      //     : callback(null);
      // });
    })
    .catch((error) => {
      callback(error);
    });
  // err && callback(null);
}

export function registerAcademyMethod({
  email,
  firstName,
  lastName,
  token,
  callback,
}) {
  instance({
    method: "POST",
    url: "/api/invitations/self-invite",
    data: {
      email,
      firstName,
      lastName,
    },
    params: {
      token: `${token}`,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      callback(null);
    });
}

export function registerMethod({
  email,
  companyName,
  firstName,
  lastName,
  role,
  callback,
}) {
  instance({
    method: "POST",
    url: "/api/companies/register",
    data: {
      companyMailDomain: email.substring(email.lastIndexOf("@") + 1),
      companyName,
      email,
      firstName,
      lastName,
      role,
    },
    params: {
      redirectUrl: `/`,
      targetLink: `${window._env_.REACT_APP_GRATUITY_APP_BASE_URL}/signup`,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "",
    },
  })
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      callback(null);
    });
}

export function recoverPassword({
  newUser = false,
  recover_pwd_token,
  newPassword,
  callback,
}) {
  let err = true;
  let endopint = newUser
    ? `/api/user-accounts/finalize-registration?token=${recover_pwd_token}`
    : `/api/user-accounts/recover-password?token=${recover_pwd_token}`;
  instance

    .put(endopint, {
      newPassword,
    })
    .then((res) => {
      err = false;
      callback(res);
    })
    .catch((error) => {
      callback(null);
    });
}

export function signupMethod({
  password,
  token,
  email,
  firstName,
  lastName,
  callback,
}) {
  instance({
    method: "POST",
    url: `/api/user-accounts/signup?token=${token}&targetLink=${window._env_.REACT_APP_GRATUITY_APP_BASE_URL}`,
    data: {
      password,
      termsConditionsUri: "https://finflx.com/terms-of-use",
      dataPrivacyPolicyUri: "https://finflx.com/privacy-policy",
      email,
      firstName,
      lastName,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      callback(null);
    });
}

export function socialRegisterMethod({
  // step #1 on register
  companyName,
  code,
  provider,
  providedEmail,
  callback,
}) {
  instance({
    method: "POST",
    url: `/api/companies/social-register?redirectUrl=${window.location.protocol}//${window.location.host}`,
    data: {
      companyName: companyName,
      provider_auth_code: code,
      providedEmail,
      provider: provider, // GOOGLE
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      callback(res.data); // access_token
    })
    .catch((error) => {
      callback(null);
    });
}

export function socialSignupMethod({ token, code, provider, callback }) {
  instance({
    method: "POST",
    url: `/api/user-accounts/social-signup?targetLink=${window._env_.REACT_APP_GRATUITY_APP_BASE_URL}&redirectUrl=${window.location.protocol}//${window.location.host}`,
    data: {
      token,
      provider_auth_code: code,
      provider: provider, // GOOGLE
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      callback(res.data); // access_token
    })
    .catch((error) => {
      callback(null);
    });
}

export function googleAuthCodeLoginMethod({ code, callback }) {
  // for login
  const formData = new URLSearchParams();
  formData.append("code", code);
  formData.append(
    "redirect_uri",
    `${window.location.protocol}//${window.location.host}`
  );

  instance
    .post("/g-auth", formData)
    .then((res) => {
      callback(res.data); // same res as login
    })
    .catch((err) => {
      callback(null);
    });
}
export function microsoftAuthCodeLoginMethod({ code, callback }) {
  // for login
  const formData = new URLSearchParams();
  formData.append("code", code);
  formData.append(
    "redirect_uri",
    `${window.location.protocol}//${window.location.host}`
  );

  instance
    .post("/m-auth", formData)
    .then((res) => {
      callback(res.data); // same res as login
    })
    .catch((err) => {
      callback(null);
    });
}

export function googleAccessTokenLoginMethod({ access_token, callback }) {
  // step #2 for register & signup
  const formData = new URLSearchParams();
  formData.append("access_token", access_token);
  formData.append(
    "redirect_uri",
    `${window.location.protocol}//${window.location.host}`
  );

  instance
    .post("/g-auth", formData)
    .then((res) => {
      callback(res.data); // same res as login
    })
    .catch((err) => {
      callback(null);
    });
}
export function microsoftAccessTokenLoginMethod({ access_token, callback }) {
  // step #2 for register & signup
  const formData = new URLSearchParams();
  formData.append("access_token", access_token);
  formData.append(
    "redirect_uri",
    `${window.location.protocol}//${window.location.host}`
  );

  instance
    .post("/m-auth", formData)
    .then((res) => {
      callback(res.data); // same res as login
    })
    .catch((err) => {
      callback(null);
    });
}
export function verifyEmailAddress({ email, callback }) {
  axios
    .post(
      "https://api.clearout.io/v2/email_verify/instant",

      { email },
      {
        headers: {
          // ["Content-Type"]: "application/json;charset=utf-8",
          // // ["Access-Control-Allow-Origin"]: "*",
          // ["access-control-allow-origin"]: "https://app.clearout.io",
          // ["access-control-allow-credentials"]: true,
          // ["access-control-allow-headers"]:
          //   "Origin, X-Requested-With, Content-Type, Accept, Authorization, X-Co-App-Version, X-CO-TAGS, Cache-Control",
          // Authorization: window._env_.CLEAROUT_API_KEY,
        },
      }
    )
    .then((res) => {
      callback(res.data, res.status); // access_token
    })
    .catch((error) => {
      callback(null);
    });
}
export function selectSubsidiaryTokenHandler({
  requested_subject,
  historyObject,
  callback,
  isMain = false,
  errCallback = () => {},
}) {
  if (isMain) {
    sessionStorage.setItem("isImpersonated", false);
    postSubsidiaryLogin(
      {
        refresh_token: sessionStorage.getItem("parentRefreshToken"),
        access_token: sessionStorage.getItem("parentAccessToken"),
      },
      historyObject
    );
  } else {
    const formData = initTokenExchangeApiParams();
    formData.set("requested_subject", requested_subject);
    instance
      .post(authBaseUrl, formData)
      .then((res) => {
        sessionStorage.setItem("requested_subject", requested_subject);
        sessionStorage.setItem("isImpersonated", true);

        postSubsidiaryLogin(res.data, historyObject);

        // callback(res.data, res.status);
      })
      .catch((err) => errCallback(err?.response?.data, err.response.status));
  }
}
export const postSubsidiaryLogin = (res, historyObject) => {
  if (res && res.access_token) {
    sessionStorage.setItem("refreshToken", res.refresh_token);
    sessionStorage.setItem("accessToken", res.access_token);
    useJwt.setRefreshToken(res.refresh_token);
    useJwt.setToken(res.access_token);
    meMethod({
      token: res.access_token,
      callback: (account_data) => {
        if (account_data) {
          sessionStorage.setItem("userData", JSON.stringify(account_data));

          // window.location.href = "/";
          if (historyObject) {
            historyObject.push("/");
          }
          // getCompanyState({
          //   companyId: account_data.companyId,
          //   callback: (company_data) => {
          //     // window.go("/");
          //     window.location.href = "/";
          //     if (historyObject) {
          //       // historyObject.push("/");
          //     }
          //   },
          // });
        }
      },
    });
  }
};
