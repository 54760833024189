import chroma from "chroma-js";
import jwt from "../auth/jwt/useJwt";
import { authBaseUrl } from "../views/Auth/api";
import instance from "./axios";
import countries from "./countries";

export const toMinorUnit = (amount) => {
  return amount * 100;
};

export const toMajorUnit = (amount) => {
  return amount / 100;
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const downloadFile = ({ file_id, onFinish }) => {
  instance
    .get(`/api/documents/${file_id}`, {
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.data.type,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${+new Date()}.${res.data.type.split("/")[1]}`;
      link.click();
      onFinish(true);
    })
    .catch((err) => {
      onFinish(false);
    });
};
export const objectsEqual = (o1, o2) =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
export const viewFile = ({ file_id, title = "File", onFinish }) => {
  instance
    .get(`/api/documents/${file_id}`, {
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.data.type,
      });
      let url = window.URL.createObjectURL(blob);
      let newWindow = window.open(url);
      setTimeout(function () {
        if (newWindow?.document) {
          newWindow.document.title = title;
        }
      }, 100);

      onFinish(true);
    })
    .catch((err) => {
      onFinish(false);
    });
};
export const getFileUrl = async ({ file_id }) => {
  const res = await instance.get(`/api/documents/${file_id}`, {
    responseType: "blob",
  });
  // .then((res) => {
  const blob = new Blob([res.data], {
    type: res.data.type,
  });
  let link = window.URL.createObjectURL(blob);
  return [link, res.data.type];
  // })
  // .catch((err) => {
  // return null;
  // });
};
export const getCompanyState = () => {
  let employee_onboarded = sessionStorage.getItem("employee_onboarded");
  let company_stats = sessionStorage.getItem("company_stats");
  let companyStates =
    company_stats && company_stats !== "undefined"
      ? JSON.parse(company_stats)
      : {};
  return {
    ...companyStates,
    employee_onboarded: employee_onboarded === "true",
  };
};

export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => sessionStorage.getItem("userData");
export const getUserData = () => JSON.parse(sessionStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});
// export const ubosSelectStyles = ()
export const selectCustomStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    // borderColor: state.isFocused ? "grey" : "red",
    border: "none",
    color: "#474AA6",
    fontWeight: 500,
    borderRadius: 5,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.08)",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    // borderColor: state.isFocused ? "grey" : "red",
    // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.08)",
    color: "#474AA6 ",
    fontWeight: 500,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#474AA6 ", // Custom colour
  }),
  singleValue: () => ({
    color: "#474AA6 ", // Custom colour
    fontWeight: 500,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma("#474AA6");
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#474AA6"
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : "#474AA6",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#474AA6"
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
};
export const isRole = (role, singleRole = false) => {
  /*
    expects a role to be passed in the props
    "eosb-senior-manager || academy-user || eosb-company-admin || eosb-finance || eosb-hr || eosb-user"

    returns true if the user has the role
  */
  const userData = getUserData();
  if (!userData) return false;

  if (singleRole) {
    return Boolean(
      userData.roles?.split(",").length === 2 && userData.roles?.includes(role)
    );
  }

  return userData.roles?.includes(role);
};

export const hasAllRoles = () => {
  const userData = getUserData();
  if (!userData) return false;
  return (
    userData.roles.includes("eosb-senior-manager") &&
    userData.roles.includes("eosb-company-admin") &&
    userData.roles.includes("eosb-finance") &&
    userData.roles.includes("eosb-hr")
  );
};

export const getReadableRoles = () => {
  const roles_map = {
    "eosb-company-admin": "Admin",
    "eosb-senior-manager": "Senior Manager",
    "eosb-finance": "Finance",
    "eosb-hr": "Human Resources",
    "academy-user": "Academy User",
    "eosb-user": "User",
  };
  const userData = getUserData();
  if (!userData) return null;
  return userData.roles.split(",").map((r) => roles_map[r]);
};

export const updateClipboard = (newClip, callback) => {
  navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    if (result.state == "granted" || result.state == "prompt") {
      navigator.clipboard.writeText(newClip).then(
        function () {
          /* clipboard successfully set */
          callback(true);
        },
        function () {
          /* clipboard write failed */
          callback(false);
        }
      );
    }
  });
};

export const uuidv4 = () => {
  let k = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

  return k;
};

export const arrayMax = (arr) => {
  return arr.reduce(function (p, v) {
    return p > v ? p : v;
  });
};
export const arrayMin = (arr) => {
  return arr.reduce(function (p, v) {
    return p < v ? p : v;
  });
};

export const countriesList = countries;

export const initRefreshTokenParams = () => {
  let params = new URLSearchParams();
  params.append("client_id", "eosb-orch-browser");
  params.append("grant_type", "refresh_token");
  if (isImpersonated()) {
    // refresh the parent token if the user is impersonated
    params.append("refresh_token", jwt.getParentRefreshToken());
  } else {
    params.append("refresh_token", jwt.getRefreshToken());
  }
  return params;
};
export const initTokenExchangeApiParams = () => {
  let subject_token = jwt.getParentToken(),
    reqSubject = sessionStorage.getItem("requested_subject");
  let params = new URLSearchParams();
  params.append("grant_type", "token-exchange");
  params.append("requested_subject", reqSubject);
  params.append("subject_token", subject_token);
  return params;
};
export const getProviderAuthUrl = () => {
  let identity_provider = JSON.parse(
    sessionStorage.getItem("userData")
  ).identityProvider;
  let authUrl =
    identity_provider === "GOOGLE"
      ? "/g-auth"
      : identity_provider === "MICROSOFT"
      ? "/m-auth"
      : authBaseUrl;
  return authUrl;
};
export const isImpersonated = () => {
  let isImpersonatedUser = jwt.getIsImpersonated();
  if (isImpersonatedUser === undefined) return false;
  if (isImpersonatedUser == "true") {
    return true;
  } else return false;
};

export const updateSessionTokens = ({
  access_token,
  refresh_token,
  isParentTokens = false,
}) => {
  if (isImpersonated()) {
    //Should update parent tokens or current tokens
    if (isParentTokens) {
      jwt.setParentToken(access_token);
      jwt.setParentRefreshToken(refresh_token);
    } else {
      jwt.setToken(access_token);
      jwt.setRefreshToken(refresh_token);
    }
  } else {
    jwt.setToken(access_token);
    jwt.setRefreshToken(refresh_token);
    jwt.setParentToken(access_token);
    jwt.setParentRefreshToken(refresh_token);
  }
};
export const authRequestHeaders = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export const refreshToken = ({
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {},
}) => {
  // Refresh Parent tokens
  instance
    .post(getProviderAuthUrl(), initRefreshTokenParams(), authRequestHeaders)
    .then((res) => {
      updateSessionTokens({ ...res.data, isParentTokens: true });
      if (isImpersonated()) {
        // Refresh impersonated user tokens
        instance
          .post(
            getProviderAuthUrl(),
            initTokenExchangeApiParams(),
            authRequestHeaders
          )
          .then((res) => {
            updateSessionTokens({ ...res.data, isParentTokens: false });
            onSuccess(res);
          })
          .catch((err) => {
            onError(err);
          })
          .finally(onFinally);
      } else {
        onSuccess(res);
      }
    })
    .catch((err) => {
      onError(err);
    })
    .finally(() => {
      if (!isImpersonated()) {
        onFinally();
      }
    });
};

export const decimalAdjust = (type, value, exp) => {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
};
function companyInfoCompleted({ meData, formData }) {
  let isReady = false;

  if (meData?.companyId) {
    isReady =
      meData?.bankAccount?.account_holder &&
      meData?.bankAccount?.bank_name &&
      meData?.bankAccount?.branch_name &&
      meData?.bankAccount?.swift_code &&
      meData?.bankAccount?.account_number &&
      meData?.bankAccount?.iban &&
      meData?.ubos?.length &&
      meData?.tradeLicenseId &&
      meData?.incorporationCertificateId &&
      meData?.address &&
      meData?.city &&
      meData?.companyId &&
      meData?.companyName &&
      meData?.countryCode &&
      meData?.legalName &&
      meData?.licenseExpiryDate &&
      meData?.licensingAuthorityCode &&
      meData?.tradeLicenseNumber;
  }

  return isReady;
}
export const companyInfoChanged = ({ meData, formData, myubos }) => {
  let changed =
    meData?.companyName === formData.company_trade_name &&
    meData?.companyId === meData.data.companyId &&
    meData?.legalName === formData.company_legel_name &&
    meData?.countryCode === formData.country.countryCode &&
    meData?.city === formData.city.city &&
    meData?.address === formData.street_address &&
    meData?.businessActivityCodes === formData.license_activity?.activityCode &&
    meData?.tradeLicenseNumber === formData.license_number &&
    !myubos?.length;
  return changed;
};
export const sortLiabilityChartSeries = (arr) => {
  if (arr?.length && arr[0].name) {
    return [...arr].sort((a, b) => {
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
      return 0;
    });
  } else {
    return null;
  }
};
export const handleSimAction = (sim) => {
  const approvalProcessKeys = [
    { status: "CREATED", role: "eosb-hr" },
    { status: "PENDING_FINANCE", role: "eosb-finance" },
    { status: "PENDING_MANAGEMENT", role: "eosb-senior-manager" },
    { status: "APPROVED", role: null },
  ];
  let simulationRole = approvalProcessKeys.find(
    (item) => item.status === sim.status
  );
  let canApprove = simulationRole?.role ? isRole(simulationRole?.role) : false;
  return canApprove;
};
export const motionPopupVlaues = {
  variants: {
    enter: (direction) => {
      return {
        y: direction > 0 ? 200 : -200,
        scaleX: 0.1,
        scaleY: 0.9,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      scaleX: 1,
      scaleY: 1,

      y: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        scaleX: 0.1,
        scaleY: 0.9,

        y: direction < 0 ? 200 : -200,
        opacity: 0,
      };
    },
  },
  transitions: {
    all: { type: "spring", stiffness: 300, damping: 30 },
    opacity: { duration: 0.1 },
    scaleX: {
      duration: 0.6,
      type: "spring",
      stiffness: 500,
      damping: 30,
    },
    scaleY: {
      duration: 0.3,
      type: "spring",
      stiffness: 500,
      damping: 30,
    },
    y: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
  },
};
// // Decimal round
// if (!Math.round10) {
//   Math.round10 = function (value, exp) {
//     return decimalAdjust("round", value, exp);
//   };
// }
// // Decimal floor
// if (!Math.floor10) {
//   Math.floor10 = function (value, exp) {
//     return decimalAdjust("floor", value, exp);
//   };
// }
// // Decimal ceil
// if (!Math.ceil10) {
//   Math.ceil10 = function (value, exp) {
//     return decimalAdjust("ceil", value, exp);
//   };
// }
