import mock from "../mock";

import { dashboardData } from "./dashboard_data";

// ------------------------------------------------
// GET: Return the employees summary view
// ------------------------------------------------
mock.onGet("/api/dashboard").reply(() => {
  return [200, dashboardData];
});
