// **  Initial State
const initialState = {
  isIdle: false,
};

const idleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_IDLE": {
      return { ...state, isIdle: action.isIdle };
    }
    default:
      return state;
  }
};

export default idleReducer;
